import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Figure from 'react-bootstrap/Figure';

const Content = () => (
  <Container fluid>
      <Container>
      <Accordion flush>
       {/* <Accordion.Item eventKey="0">
         <Accordion.Header>The Currents Bank: A Next-Generation Distributed Economy Banking System, WIP</Accordion.Header>
         <Accordion.Body>
          <a href="#">pdf</a>
         </Accordion.Body>
       </Accordion.Item> */}
       {/* <Accordion.Item eventKey="1">
         <Accordion.Header>The Reparations Bank of America, <i>ongoing</i></Accordion.Header>
         <Accordion.Body>
          <Figure>
          <Figure.Image src="/images/rboa_2023_a.jpeg" fluid />
          <Figure.Caption>
            Data Card Printer, HID Global OMNIKEY, USB Card Reader/Writer, SLE4442 PVC Data Cards, Personal Computer (Ubuntu 14.04), MagCard Reader/Writer (Software), Internet Access (Registration Page), RBA Card Agreement Form [8’ x 8’], <a href="https://www.reparationsbank.us/">reparationsbank.us</a>
          </Figure.Caption>
          </Figure>
         </Accordion.Body>
       </Accordion.Item> */}
       {/* <Accordion.Item eventKey="2">
         <Accordion.Header>Untitled, 2023</Accordion.Header>
         <Accordion.Body>
         <Figure>
          <Figure.Image src="/images/untitled_2023_a.jpeg" fluid />
          <Figure.Image src="/images/untitled_2023_b.jpeg" fluid />
          <Figure.Image src="/images/untitled_2023_c.jpeg" fluid />
          <Figure.Caption>
           $10 Million of Decommissioned US Bank Notes (Trash), Trash bags, Musca domestica (5), Acrylic, Print Documentation, Audio Documentation, Value of Life Documents (EPA, FEMA, DOT) (see Appendix C)
           </Figure.Caption>
           </Figure>
         </Accordion.Body>
       </Accordion.Item> */}
       <Accordion.Item eventKey="3">
         <Accordion.Header>How to Own Land, 2023</Accordion.Header>
         <Accordion.Body>
         <Figure>
          <Figure.Image src="/images/own_land_2023_a.jpeg" fluid />
          <Figure.Image src="/images/own_land_2023_b.jpeg" fluid />
          <Figure.Image src="/images/own_stone_2023_a.jpeg" fluid />
          <Figure.Caption>
           (i) Stolen Land 10.44” x 24.56” x 6”
           (ii) Stolen Land 10.44” x 24.56” x 6” with offerings
           (iii) River Stone, SLE4442 Chip Banking Card
          </Figure.Caption>
          </Figure>
         </Accordion.Body>
       </Accordion.Item>
       <Accordion.Item eventKey="4">
         <Accordion.Header>Sussunzione, 2022-2023</Accordion.Header>
         <Accordion.Body>
         <Figure>
          <Figure.Image src="/images/pestimony_red_2022_a.jpeg" fluid /><br />
          <Figure.Image src="/images/pestimony_red_2022_b.jpeg" fluid /><br />
          <Figure.Image src="/images/pestimony_red_2022_c.jpeg" fluid /><br />
          <Figure.Image src="/images/pestimony_env_2023_a.jpeg" fluid /><br />
          <Figure.Image src="/images/pestimony_env_2023_b.jpeg" fluid /><br />
          <Figure.Image src="/images/pestimony_bluehunnit_2023.png" fluid />
          <Figure.Caption>
           (i) Eastern Subterranean Termite, Workers (approx. 200), Federal Reserve Note (One-Hundred Dollar U.S. Note), Calcium Sand, Damp Wood, Extruded Acrylic, Construction Material and Hardware, Bankers Boxes®, Habitat Camera, Live-streaming Software, For Reference PDF, Important Notices! PDF
           (ii) Eastern Subterranean Termite, Workers (approx. 200), Federal Reserve Note (One-Hundred Dollar U.S. Notes), Climate Controlled Environment, Habitat Camera, Projector
           (iii) Blue Hunnit (One-Hundred Dollar Federal Reserve Bank Note, In Processing)
          </Figure.Caption>
          </Figure>
         </Accordion.Body>
       </Accordion.Item>
       <Accordion.Item eventKey="5">
         <Accordion.Header>Untitled, 2019</Accordion.Header>
         <Accordion.Body>
         <Figure>
          <Figure.Image src="/images/untitled_2019_12.png" fluid />
          <Figure.Caption>
           Androgynous mannequin, projector, computer + software, security camera + router, tripod, concealed microphone + speaker, extruded aluminum structural materials, mirror panes, polycarbonate panes, approx. 320 sq. ft.
           </Figure.Caption>
           </Figure>
         </Accordion.Body>
       </Accordion.Item>
       <Accordion.Item eventKey="6">
         <Accordion.Header>Balance, 2018</Accordion.Header>
         <Accordion.Body>
         <Figure>
          <Figure.Image src="/images/balance_2018.jpg" fluid />
          <Figure.Caption>
          Balloon x 3, string, hammer, chisel "subtle bodies being in suspense" Henry Corbin, <i>Mundus Imaginalis</i>
          </Figure.Caption>
          </Figure>
         </Accordion.Body>
       </Accordion.Item>
     </Accordion>
     <footer>
      <code>Copyright © 2025 FS Bàssïbét, All Rights Reserved</code>
    </footer>
      </Container>
  </Container>

);

export default Content;
